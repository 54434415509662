<template>
    <div>
        <Header></Header>
        <div class="page-holder">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="top-left-holder">
                            <h2>Join 10,000 Landlords to Access Free Legal Services</h2>
                            <p>At Shelta, we’ve partnered with top Nigerian lawyers to provide free legal services for 10,000 property owners facing disputes.</p>
                            <div class="top-button">
                              <button class="btn">Get legal help for free!</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="top-right-holder">
                          <img src="../assets/litigation/legal1.png" alt="legal">
                        </div>
                    </div>
                </div>
            </div>

            <div class="" style="background-color: #F5F8FC; margin-top: 4rem; margin-bottom: 4rem; padding-bottom: 3rem;">
              <div class="container">
                <h4 class="why-litigation-text">Why Free Legal Services for Landlords?</h4>

                <div class="row">
                  <div class="col-md-6">
                    <div class="second-left">
                      <img src="../assets/litigation/why-img.png" alt="why-litigation">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="second-right">
                      <p>
                        The growing backlog of unresolved court cases between property owners and tenants has become a significant challenge in Nigeria, causing financial strain and prolonged disputes. Recognizing this issue, Shelta, an innovative PropTech solution, is dedicated to providing landlords with the support they need to navigate these legal complexities efficiently. By offering streamlined solutions, Shelta aims to reduce the stress, time, and costs associated with tenant-landlord disputes, ensuring a more seamless and hassle-free property management experience.
                      </p>
                      <div class="second-right-btn">
                        <button class="btn">Learn More</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="" style="background-color: #F5F8FC; margin-top: 4rem; margin-bottom: 4rem; padding-bottom: 3rem;">
              <div class="container">
                <h4 class="why-litigation-text" style="margin-bottom: 0 !important; line-height: 36px !important;">We Are Offering</h4>
                <p class="in-partnership-text">In partnership with leading lawyers</p>
                <div class="row">
                  <div class="col-md-6">
                    <div class="offering-box">
                      <img src="../assets/litigation/legal-icon.png" alt="">
                      <h6>Free Litigation Cover</h6>
                      <p>Get expert legal support at no cost to resolve property disputes and protect your rights.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="offering-box">
                      <img src="../assets/litigation/cone.png" alt="">
                      <h6>Guaranteed rent solutions</h6>
                      <p>Get reliable, consistent rent payments without the worry of delays or vacancies.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="offering-box">
                      <img src="../assets/litigation/person.png" alt="">
                      <h6>Data-driven tenant verification</h6>
                      <p>Ensure secure and trustworthy tenants with our thorough, data-backed verification process.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="offering-box">
                      <img src="../assets/litigation/money.png" alt="">
                      <h6>Renovation funding support</h6>
                      <p>We help you get the funds needed to renovate and enhance your property.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="" style="background-color: #F5F8FC; margin-top: 4rem; margin-bottom: 4rem; padding-bottom: 3rem;">
              <div class="container">
                <h4 class="why-litigation-text" style="margin-bottom: 0 !important; line-height: 36px !important;">We Are Offering</h4>
                <p class="in-partnership-text">In partnership with leading lawyers</p>
                <div class="row">
                  <div class="col-md-4">
                    <div class="offering-box box-2">
                      <img src="../assets/litigation/cone.png" alt="">
                      <h6>Expert Legal Representation</h6>
                      <p>Resolve tenant disputes quickly and effectively with professional legal support.</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="offering-box box-2">
                      <img src="../assets/litigation/person.png" alt="">
                      <h6>Zero Litigation Costs</h6>
                      <p>Save money while protecting your rights with expert legal support at no charge</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="offering-box box-2">
                      <img src="../assets/litigation/money.png" alt="">
                      <h6>Access to Other Shelta’s Services</h6>
                      <p>Simplified rent recovery and seamless property management for stress-free ownership.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="" style="margin-top: 4rem; padding-bottom: 3rem;">
              <div class="container">
                <h4 class="why-litigation-text" style="margin-bottom: 0 !important; line-height: 36px !important;">Who can Apply</h4>
                <p class="in-partnership-text">Property owners across Nigeria with:</p>
                <div class="row">
                  <div class="col-md-4">
                    <div class="offering-box who-can-apply-box">
                      <img src="../assets/litigation/legal-icon.png" alt="">
                      <p>Commercial or residential properties owners.</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="offering-box who-can-apply-box">
                      <img src="../assets/litigation/cone.png" alt="">
                     
                      <p>Landlords with existing or potential tenant disputes.</p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="offering-box who-can-apply-box">
                      <img src="../assets/litigation/person.png" alt="">
                      
                      <p>Landlords with commitment to adopting smarter property management practices.</p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

            <div class="container-fluid legal-form">
              <h4 class="why-litigation-text">Enjoy Free Legal Assistance Now!</h4>
              <div class="container">
                <div class="form">
                  <h5>Fill out the form below to access free litigation support.</h5>
                  <div class="form-group">
                    <input class="form-control" v-model="fname" type="text" name="fname" id="fname" placeholder="First name"
                    :class="{ errorData: info && fname === '' }"
                    >
                  </div>

                  <div class="form-group">
                    <input class="form-control" v-model="lname" type="text" name="lname" id="lname" placeholder="Last name" 
                    :class="{ errorData: info && lname === '' }"
                    >
                  </div>
                  <div class="form-group">
                    <input class="form-control" v-model="email" type="email" name="email" id="email" placeholder="Email" 
                    :class="{ errorData: info && email === '' }"
                    >
                  </div>
                  <div class="form-group">
                    <input class="form-control" v-model="phone" type="number" name="phone" id="phone" placeholder="Phone" 
                    :class="{ errorData: info && phone === '' }"
                    >
                  </div>
                  <div class="form-group">
                    <select class="form-control" name="state" id="state" @change="getCities()"
                    v-model="stateid"
                    :class="{ errorData: info && stateid === '' }"
                    >
                    <option
                      selected
                      disabled
                      value=""
                      style="color: #AAADBA !important;"
                      >--Select state--</option
                    >
                      <option
                      v-for="(item, index) in states"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <select class="form-control" name="state" id="state" @change="getCities()"
                    v-model="cityid"
                    :class="{ errorData: info && cityid === '' }"
                    >
                    <option
                      selected
                      disabled
                      value=""
                      style="color: #AAADBA !important;"
                      >--Select city--</option
                    >
                      <option
                      v-for="(item, index) in cities"
                      :key="index"
                      :value="item.id"
                      >{{ item.name }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input class="form-control" v-model="address" type="text" name="address" id="address" placeholder="Property address"
                    :class="{ errorData: info && address === '' }"
                    >
                  </div>

                  <div class="form-group">
                    <select class="form-control" name="" id="" v-model="propertyType" :class="{ errorData: info && propertyType === '' }">
                      <option value="Commercial">Commercial</option>
                      <option value="Residential">Residential</option>
                    </select>
                  </div>

                  <div class="form-group form-check mb-3 mt-4 p-checkbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="propOwner"
                      true-value="YES"
                      false-value="NO"
                    />
                    <label class="form-check-label">Are you the property owner?</label>
                  </div>

                  <div class="form-group form-check mb-3 mt-4 p-checkbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="inCourt"
                      true-value="YES"
                      false-value="NO"
                    />
                    <label class="form-check-label">Are you in court already?</label>
                  </div>
                  <div class="form-group">
                    <textarea class="form-control" v-model="description" name="description" id="description" cols="20" rows="5" placeholder="Brief description of tenant dispute (optional)"></textarea>
                  </div>

                  <div class="form-group form-check mb-3 mt-4 p-checkbox">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      v-model="termsConditions"
                      true-value="YES"
                      false-value="NO"
                      :class="{ errorData: info && termsConditions === '' }"
                    />
                    <!-- <label class="form-check-label">I agree to the <span class="terms-text" data-toggle="modal" data-target="#termsCondition">terms and conditions</span></label> -->
                    <label class="form-check-label">I agree to the <span class="terms-text">terms and conditions</span></label>

                  </div>

                  <div class="form-group get-started">
                    <button :disabled="loading" class="btn" @click="submit()">{{ !loading ? 'Get started today' : 'processing...' }}</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Terms and conditions -->
            <div class="modal fade" tabindex="-1" id="termsCondition" style="margin-top: 6rem; margin-bottom: 10rem;"
            role="dialog"
            aria-labelledby="termsCondition"
            aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered ">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Terms and conditions</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p class="terms-conditions-body">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                   
                  </div>
                </div>
              </div>
            </div>
            <!-- Terms and conditions -->
        </div>
        <FooterMain></FooterMain>
    </div>
</template>


<script>
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
// import PageLoader from "../components/PageLoader.vue";
import { Service } from "../store/service";
import $ from "jquery";
const Api = new Service();

export default {
  name: "Litigation",
  components: {
    Header,
    FooterMain,
    
  },
//   props: {
//     propertyList: {
//       type: Array,
//       required: true,
//     },
//     // loading: {
//     //   type: Boolean,
//     //   required:true,
//     // }
//   },
  data() {
    return {
      loading: false,
      info: false,
      propOwner: '',
      propertyType: "",
      inCourt: '',
      fname: '',
      lname: '',
      email: '',
      phone: '',
      address: '',
      state: '',
      stateid: "",
      states: [],
      cityid: '',
      city: '',
      cities: [],
      description: '',
      termsConditions: '',
      address: ""
    };
  },
  directives: {
    transform(el, binding) {
      const transformType = binding.value;
      if (transformType === 'uppercase') {
        el.textContent = el.textContent.toUpperCase();
      } else if (transformType === 'lowercase') {
        el.textContent = el.textContent.toLowerCase();
      }
    },
  },
  mounted() {
    this.getStates();
  },
  methods: {
    async submit() {
      this.loading = true;
      this.info = true;
      const payload = {
        firstname: this.fname,
        lastname: this.lname,
        email: this.email,
        phone: this.phone,
        city_id: this.cityid,
        state_id: this.stateid,
        existing_dispute: this.inCourt,
        terms_and_conditions: this.termsConditions,
        propert_owner: this.propOwner,
        // litigation_cover_for_other_properties: this.litigation_cover_for_other_properties,
        // property_management_with_guaranteed_rent: this.property_management_with_guaranteed_rent,
        address: this.address,
      }
      if (this.fname === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter first name");
      }
      if (this.lname === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter last name");
      }
      if (this.email === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter email name");
      }
      if (this.phone === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter phone number");
      }
      if (this.stateid === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select state");
      }
      if (this.cityid === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select city");
      }
      if (this.address === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Enter address");
      }
      if (this.propertyType === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select propert Type");
      }
      if (this.termsConditions === "") {
        this.info = true;
        this.loading = false;
        return this.$store.commit("setApiFailed", "Select terms and condition");
      }
      try {
        await Api.postRequest('litigation_sign_up', payload).then((res) => {
          // console.log("Litigaton", res);
          if (res.data.success) {
            this.fname = '';
            this.lname = '';
            this.email = '';
            this.phone = '';
            this.cityid = '';
            this.stateid = '';
            this.inCourt = '';
            this.termsConditions = '';
            this.propOwner = '';
            return this.$store.commit("setApiSuccess", "Success");
          }
          this.loading = false;
          this.info = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
          this.info = false;
        });
      } finally {
        // console.log("Closed")
      }
    },
    async getStates() {
      // const id = this.countryId;
      try {
        await Api.getRequest(`fetchstatesbycountryid/${161}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            // console.log("states", res)
            if (res.data.success) {
              this.states = res.data.states;
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getCities: async function() {
      const id = this.stateid;
      // this.$store.commit("setApiWarning", "Loading cities");
      try {
        await Api.getRequest(`fetchcitiesbystateid/${id}`)
          .then(res => {
            // this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              // console.log("City", res)
              this.cities = res.data.cities;
              // return this.$store.commit("setApiSuccess", res.data.success);
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    
  }
};
</script>


<style scoped lang="scss">

$error: #dc3545;

.top-left-holder {
  margin-bottom: 25px;
    h2, p {
        color: #222222;
        font-family: Lato;
        letter-spacing: 5%;
        text-align: left;
    }
    h2 {
        
        font-weight: 700;
        font-size: 51px;
        line-height: 66px;
       
    }
    p {
        font-family: Lato;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        
    }
    
}
.top-button {
  display: flex;
  button {
      color: #ffffff;
      background: #0672EF;
      font-family: Lato;
      font-weight: 400;
      font-size: 16px;
      outline: 0;
      box-shadow: none;
    }
}
.page-holder {
    margin-top: 10rem;
    // height: 100vh;
    // overflow-x: hidden; /* Prevents horizontal scroll */
    // overflow-y: auto; 
}

.top-right-holder, .second-left {
  img {
    width: 100%;
  }
}

.why-litigation-text {
  font-family: Lato;
  font-weight: 600;
  font-size: 30px;
  line-height: 55px;
  letter-spacing: 5%;
  text-align: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.second-right {
  p {
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    // letter-spacing: 0.5%;
    color: #222222;
    text-align: justify;
  }
  .second-right-btn {
    display: flex;
    margin-top: 14px;
    button {
      border: 1px solid #0033EA;
      color: #0033EA;
      font-family: Lato;
      font-weight: 400;
      font-size: 16px;
      outline: 0;
      box-shadow: none;
    }
  }
}

.in-partnership-text {
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 5%;
  text-align: center;
  color: #222222;
  margin-bottom: 3rem;
}

.offering-box {
  border: 1px solid #c7d1fc;
  border-radius: 10px;
  background: #FFFFFF;
  min-height: 40px;
  padding: 15px;
  margin-bottom: 15px;
  text-align: left;
  // max-width: 87%;
  min-height: 165px;

  h6 {
    font-family: Lato;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    // letter-spacing: 5%;

  }
  p {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    // letter-spacing: 5%;
  }
}
.box-2 {
  text-align: center !important;
  padding: 30px !important;
  min-height: 220px !important;
}

.legal-form {
  background: #F5F8FC;
}

@media screen and (min-width: 1024px) and (max-width: 2560px) {
  .form {
    padding: 80px;
    width: 60%;
  }
}
.form {
  background: #ffffff;
  border-radius: 20px;
  // padding: 0 15px;
  // padding: 80px;
  // width: 60%;
  margin: auto;
  // margin: 0 200px 0 200px;
  h5 {
    font-family: Lato;
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    // letter-spacing: 5%;
    text-align: center;
    color: #0033EA;
    margin-bottom: 25px;
  }
  .form-control {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    // height: 44px;
  }
  textarea {
    border-left: 1px solid #ced4da !important;
    border-right: 1px solid #ced4da !important;
    border-top: 1px solid #ced4da !important;
  }
}

.p-checkbox {
  text-align: left;
  label {
    // text-align: left;
    // font-family: Gotham;
    // font-style: normal;
    // font-weight: 600;
    // font-size: 14px;
    // line-height: 125%;
    padding-left: 10px;
    // color: $offblack;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input, select, textarea {
  font-family: Lato;
  // font-weight: 400;
  // font-size: 24px;
  // line-height: 28.8px;
  // letter-spacing: 0%;

}
select {
  color: #495057;
}

.form-check-label {
  font-family: Lato;
  color: #495057;
}

.get-started {
  display: flex;
  margin-top: 40px;
  button {
    font-family: Lato;
    // font-weight: 600;
    // font-size: 20px;
    color: #ffffff;
    background: #0033EA;
    outline: 0;
    box-shadow: none;
    // min-width: 227px;
    // min-height: 40px;
  }

}

.who-can-apply-box {
  background: #E2E6F4;
  min-height: 147px !important;
  p {
    margin-top: 15px;
  }
}

input[type=text], input[type=number], select {
  height: 60px;
}


.errorData {
  border: 0.5px solid $error !important;
}

.terms-text {
  font-family: Lato;
  color: #0672EF;
  cursor: pointer;
  // line-height: 28px;
  // letter-spacing: 0.5%;

}

.terms-conditions-body {
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  text-align: justify;
  color: #222222;
}

</style>